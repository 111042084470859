# Post Scripts to be placed after page load in the footer

# $ ->
#   $('.navclick').click ->
#     #use a class, since your ID gets mangled
#     $(this).sidebar 'active'
#     #add the class to the clicked element
#     return
#   return

$('.navclick').click ->
  $('#sidebar').toggleClass 'active'
  # $('#toggle').toggleClass 'slide-tog'
  return
